// eslint-disable-next-line no-restricted-imports
import {fire, on} from 'delegated-events'
import {onInput, onKey} from './onfocus'
import type AutocompleteElement from '@github/auto-complete-element'
import type {EditorFromTextArea} from 'codemirror'
import {TemplateInstance} from '@github/template-parts'
import {debounce} from '@github/mini-throttle'
// eslint-disable-next-line no-restricted-imports
import {observe} from 'selector-observer'
import {parseHTML} from './parse-html'

observe('.js-add-secret-format-button', {
  add() {
    window.postProcessingExpressionCount = 0

    const postProcessingExpressionCountElement = document.querySelector<HTMLElement>(
      '.js-post-processing-expression-count'
    )

    if (postProcessingExpressionCountElement && postProcessingExpressionCountElement.textContent) {
      window.postProcessingExpressionCount = parseInt(postProcessingExpressionCountElement.textContent)
    }
  }
})

on('click', '.js-add-secret-format-button', (event: Event) => {
  const addSecretFormatButton = <HTMLElement>event.currentTarget
  if (!addSecretFormatButton) {
    return
  }

  if (window.postProcessingExpressionCount < getMaxPostProcessingExpressions()) {
    const additionalSecretFormatElements = document.querySelectorAll<HTMLElement>('.js-additional-secret-format')
    if (!additionalSecretFormatElements) {
      return
    }

    // Elements with has-removed-contents are hidden. Find the first available such
    // element and display it.
    for (const element of additionalSecretFormatElements) {
      if (element.classList.contains('has-removed-contents')) {
        element.classList.toggle('has-removed-contents', false)
        window.postProcessingExpressionCount++

        // Hide the 'Add requirements' button if the maximum allowed number of expressions are displayed.
        if (window.postProcessingExpressionCount === getMaxPostProcessingExpressions()) {
          addSecretFormatButton.hidden = true
        }

        break
      }
    }
  }
})

on('click', '.js-remove-secret-format-button', (event: Event) => {
  const addSecretFormatButton = document.querySelector<HTMLElement>('.js-add-secret-format-button')
  if (!addSecretFormatButton) {
    return
  }

  const removeSecretFormatButton = <HTMLElement>event.currentTarget
  if (!removeSecretFormatButton) {
    return
  }

  const additionalSecretFormatElement = removeSecretFormatButton.closest<HTMLElement>('.js-additional-secret-format')!
  if (!additionalSecretFormatElement) {
    return
  }

  // Clear out the expression's input before hiding it, so it shows up as
  // a new input element the next time it is unhidden.
  additionalSecretFormatElement.classList.toggle('has-removed-contents', true)
  const inputElement = additionalSecretFormatElement.getElementsByClassName(
    'js-post-processing-input'
  )[0] as HTMLInputElement
  inputElement.value = ''
  const inputElementRules = Array.from(
    additionalSecretFormatElement.getElementsByClassName('js-post-processing-input-rule')
  )
  // Clear out the error state for the input element.
  const erroredElement = additionalSecretFormatElement.getElementsByClassName('errored')[0] as HTMLElement
  if (erroredElement) {
    erroredElement.classList.toggle('errored', false)
  }
  // Remove temporary post-processing elements from form
  for (const element of inputElementRules) {
    document.getElementById(`${element.id}_hidden`)?.remove()
  }
  document.getElementById(`${inputElement.id}_hidden`)?.remove()

  debouncedTestPatternMatches(window.codeEditor.getValue())
  window.postProcessingExpressionCount--

  // Re-display 'Add requirement' button if the number of displayed inputs is under the limit.
  if (window.postProcessingExpressionCount < getMaxPostProcessingExpressions()) {
    addSecretFormatButton.hidden = false
  }
})

observe('.js-test-code', {
  async add() {
    const testCodeTextArea = document.querySelector('.js-test-code') as HTMLTextAreaElement
    const editorHeight = testCodeTextArea.clientHeight

    const CodeMirror = await import('codemirror')
    window.codeEditor = CodeMirror.default.fromTextArea(testCodeTextArea, {
      lineNumbers: false,
      lineWrapping: true,
      mode: 'text/x-yaml',
      inputStyle: 'contenteditable',
      value: testCodeTextArea.value,
      lineSeparator: '\r\n',
      theme: 'github-light'
    })

    if (editorHeight !== 0) {
      const element = document.querySelector('.CodeMirror') as HTMLElement

      if (element) {
        element.style.height = `${editorHeight}px`
        element.style.border = '1px solid #e1e4e8'
        element.style.borderRadius = '6px'
      }
    }

    window.codeEditor.save()

    window.codeEditor.on('change', () => {
      debouncedTestPatternMatches(window.codeEditor.getValue())
    })
  }
})

onInput('.js-custom-secret-scanning-pattern-form *', async function () {
  if (!window.codeEditor) {
    return
  }
  debouncedTestPatternMatches(window.codeEditor.getValue())
})

on('click', '.js-repo-selector-dialog-summary-button.disabled', (event: Event) => {
  // Summary buttons cannot be disabled directly. We add a `disabled` class to the button, but
  // the button is still clickable, so we need to short-circuit that and prevent default action.
  event.preventDefault()
})

on(
  'click',
  '.js-save-and-dry-run-button, .js-custom-pattern-submit-button, .js-org-repo-selector-dialog-dry-run-button',
  (event: Event) => {
    event.preventDefault()

    const submitButton = getSubmitButton(event)
    if (!submitButton) {
      return
    }

    // Manually set `disable-with`, or loading behavior for buttons once clicked. This prevents users from clicking a button rapidly multiple times.
    setSubmitButtonDisableWith(submitButton)

    const customPatternForm = getCustomPatternForm()
    if (!customPatternForm) return

    // Ensure we are accounting for users queueing dry runs after edit.
    if (
      submitButton.className.includes('js-save-and-dry-run-button') ||
      submitButton.className.includes('js-org-repo-selector-dialog-dry-run-button')
    ) {
      createHiddenInputField(customPatternForm, 'submit_type', 'save_and_dry_run')
    }

    // Ensure form submit events are triggered. form.submit() does not work here since it directly submits the form without handling custom behavior.
    fire(customPatternForm, 'submit')
  }
)

function getSubmitButton(event: Event): HTMLButtonElement {
  return <HTMLButtonElement>event.currentTarget
}

function setSubmitButtonDisableWith(submitButton: HTMLButtonElement) {
  submitButton.innerHTML = submitButton.getAttribute('data-disable-with') || ''
  submitButton.disabled = true
}

function getCustomPatternForm(): HTMLFormElement | null {
  return document.querySelector<HTMLFormElement>('.js-custom-secret-scanning-pattern-form')
}

const createHiddenInputField = (form: HTMLFormElement, name: string, value: string) => {
  const hiddenInput = document.createElement('input')
  hiddenInput.type = 'hidden'
  hiddenInput.name = name
  hiddenInput.id = `${name}_hidden`
  hiddenInput.value = value

  form.appendChild(hiddenInput)
  hiddenInput.required = true
}

// Debounce reaction to form changes by 300ms to avoid overload on service, and updates causing the highlighted matches to flash.
const debouncedTestPatternMatches = debounce(function (testCode: string) {
  const customPatternSubmitButton = document.querySelector<HTMLElement>('.js-custom-pattern-submit-button')
  const saveAndDryRunButton = document.querySelector<HTMLElement>('.js-save-and-dry-run-button')
  const repoSelectorDialogSummaryButton = document.querySelector<HTMLElement>('.js-repo-selector-dialog-summary-button')

  const editPatternMessageElement = document.querySelector<HTMLElement>('.js-update-pattern-info')

  const patternMatchesCountElement = document.querySelector<HTMLElement>('.js-test-pattern-matches')!
  if (!patternMatchesCountElement) return

  if (testCode.length === 0) {
    const dryRunStatusElement = document.querySelector<HTMLElement>('.js-dry-run-status')!
    if (!dryRunStatusElement) return

    // Don't disable if form is in a state where we can cancel dry run
    if (!allowDryRunCancellation(dryRunStatusElement)) {
      customPatternSubmitButton?.setAttribute('disabled', 'true')
    }

    // Do not query when the test string is empty.
    saveAndDryRunButton?.setAttribute('disabled', 'true')
    repoSelectorDialogSummaryButton?.classList.add('disabled')
    patternMatchesCountElement.textContent = ''
  } else {
    // Persist changes in test string back to the rails form version.
    window.codeEditor.save()

    const testCustomPatternForm = document.querySelector<HTMLFormElement>('.js-test-custom-secret-scanning-pattern')!
    if (!(testCustomPatternForm instanceof HTMLFormElement)) return

    const customPatternForm = getCustomPatternForm()
    if (!customPatternForm) return

    // Duplicate the form into an invisible hidden-input "test" form, for validating matches and clear old test form if exists.
    for (const element of customPatternForm.elements) {
      if (element instanceof HTMLInputElement && element.name) {
        if (element.type === 'text' || (element.type === 'radio' && element.checked)) {
          const hiddenElement = document.getElementById(`${element.name}_hidden`) as HTMLInputElement
          if (hiddenElement !== null) {
            hiddenElement.remove()
          }
          createHiddenInputField(testCustomPatternForm, element.name, element.value)
        }
      }
    }

    updatePatternMatches(
      testCustomPatternForm,
      getTestErrorHandler(
        customPatternForm,
        customPatternSubmitButton,
        saveAndDryRunButton,
        repoSelectorDialogSummaryButton,
        editPatternMessageElement
      ),
      getTestLabelUpdater(patternMatchesCountElement)
    )
  }
}, 300)

const getTestLabelUpdater = (patternMatchesCountElement: HTMLElement) => (matchesJson: string[]) => {
  if (matchesJson.length === 0) {
    patternMatchesCountElement.textContent = ' - No matches'
  } else if (matchesJson.length === 1) {
    patternMatchesCountElement.textContent = ' - 1 match'
  } else {
    // Remove duplicate matches from the list.
    const serializedArray = []
    for (const m of matchesJson) {
      serializedArray.push(JSON.stringify(m))
    }
    const serializedArrayAsSet = new Set(serializedArray)
    const uniqueSerializedArray = [...serializedArrayAsSet]
    patternMatchesCountElement.textContent = ` - ${uniqueSerializedArray.length} matches`
  }
}

/**
 * Creates a handler that receives an error or null and updates ui elements based on error content.
 * @param customPatternSubmitButton Button to enable/disable based on error received.
 * @returns true if no error received.
 */
const getTestErrorHandler =
  (
    form: HTMLFormElement,
    customPatternSubmitButton: HTMLElement | null,
    saveAndDryRunButton: HTMLElement | null,
    repoSelectorDialogSummaryButton: HTMLElement | null,
    editPatternMessageElement: HTMLElement | null
  ) =>
  (error?: ErrorWithMessage) => {
    clearInputErrorState(form)

    if (error?.message) {
      customPatternSubmitButton?.setAttribute('disabled', 'true')
      saveAndDryRunButton?.setAttribute('disabled', 'true')
      repoSelectorDialogSummaryButton?.classList.add('disabled')

      if (editPatternMessageElement) {
        // Also an indirect check for whether we are in Edit mode on the form.
        editPatternMessageElement.hidden = true
      } else {
        // In Create mode, Expand the "More options" section if there are errors relating to form elements within it.
        // In Edit mode, the section is expanded by default.
        if (
          error?.error_type === 'START_DELIMITER' ||
          error?.error_type === 'END_DELIMITER' ||
          error?.error_type === 'MUST_MATCH' ||
          error?.error_type === 'MUST_NOT_MATCH'
        ) {
          const detailsToggle = document.querySelector<HTMLElement>('.js-more-options.js-details-container')!
          if (detailsToggle) {
            detailsToggle.classList.add('open')
            detailsToggle.classList.add('Details--on')
          }
        }
      }

      showInputErrorState(form, error)

      return false
    } else {
      const modeElement = document.querySelector<HTMLElement>('.js-mode')!
      if (!modeElement) {
        return false
      }

      const dryRunStatusElement = document.querySelector<HTMLElement>('.js-dry-run-status')!
      if (!dryRunStatusElement) {
        return false
      }

      /**
       * There are 2 situations in which the submit button needs to be enabled.
       * 1) When the pattern is unpublished, and the dry run is cancelled or skipped. In this case,
       *    the button displays `Save and dry run`.
       * 2) When the pattern is being created or updated after publishing. In this case,
       *    the button displays `Save and dry run` and `Save changes` respectively.
       */
      if (
        dryRunStatusElement.textContent?.toLowerCase() === 'cancelled' ||
        dryRunStatusElement.textContent?.toLowerCase() === 'skipped' ||
        modeElement.textContent?.toLowerCase() !== 'unpublished'
      ) {
        customPatternSubmitButton?.removeAttribute('disabled')
      }

      repoSelectorDialogSummaryButton?.classList.remove('disabled')
      saveAndDryRunButton?.removeAttribute('disabled')
      if (editPatternMessageElement) {
        editPatternMessageElement.hidden = false
      }
      return true
    }
  }

function showInputErrorState(form: HTMLFormElement, error: ErrorWithMessage) {
  if (error.error_type === 'MUST_MATCH' || error.error_type === 'MUST_NOT_MATCH') {
    let idx = 0
    // Get PPE input groups
    const postProcessingExpressions = form.getElementsByClassName('js-additional-secret-format')
    for (const expressionContainer of postProcessingExpressions) {
      if (idx > (error.error_index || 0)) {
        // Something weird happened. dont mark errors
        return
      }
      const radioButtons = expressionContainer.getElementsByTagName('input')
      const checkedButton = [...radioButtons].filter(x => x.checked)
      const type = checkedButton && checkedButton[0]?.value.toUpperCase()
      // Error_index is based on the group of same type of ppe fields (must vs must_not)
      const isErrorField = type === error.error_type && idx === error.error_index
      const inputs = expressionContainer.getElementsByTagName('input')
      const expressionInput = [...inputs].filter(x => x.type === 'text')
      if (!expressionInput || expressionInput.length === 0) {
        // Something weird happened, we dont have a text input
        continue
      }
      const input = expressionInput[0]
      if (input.value === '') {
        // empty inputs are not included in the test verification request so input indexes will be off
        continue
      }
      if (isErrorField) {
        const errorInputID = input.id
        if (input && input.parentElement) {
          addErrorStylingToInput(input.parentElement)
        }
        const message = document.createElement('p')
        const messageID = `${errorInputID}_error_message`
        message.classList.add('note', 'error', 'mt-5')
        message.id = messageID
        message.textContent = error.message
        input?.setAttribute('aria-describedby', messageID)
        input?.insertAdjacentElement('afterend', message)
        return
      } else if (type === error.error_type) {
        idx++
      }
    }
  } else {
    const errorInputID = errorTypeToInputId[error.error_type]
    const input = document.querySelector<HTMLElement>(`#${errorInputID}`)
    if (input && input.parentElement) {
      addErrorStylingToInput(input.parentElement)
    }
    const message = document.createElement('p')
    const messageID = `${errorInputID}_error_message`
    message.classList.add('note', 'error')
    message.id = messageID
    message.textContent = error.message
    input?.setAttribute('aria-describedby', messageID)
    input?.insertAdjacentElement('afterend', message)
  }
}

function clearInputErrorState(form: HTMLFormElement) {
  const errorBanner = document.querySelector<HTMLElement>('.js-error-banner')!
  errorBanner.hidden = true
  for (const input of form.getElementsByTagName('input')) {
    if (input.parentElement?.classList.contains('errored')) {
      removeErrorStylingFromInput(input.parentElement)
      const errorMessageId = input.getAttribute('aria-describedby')
      document.querySelector<HTMLElement>(`#${errorMessageId}`)?.remove()
    }
  }
}

function getMaxPostProcessingExpressions() {
  const maxPostProcessingExpressionsElement = document.querySelector<HTMLElement>(
    '.js-post-processing-expression-max-count'
  )!

  if (!maxPostProcessingExpressionsElement) {
    return 5
  }

  const maxExpressionsAsString = maxPostProcessingExpressionsElement.textContent

  if (!maxExpressionsAsString) {
    return 5
  }

  return parseInt(maxExpressionsAsString)
}

function addErrorStylingToInput(inputElement: HTMLElement) {
  // Adds a red border and an error popup to the element. Since `form-group` adds
  // a vertical margin, we manually offset it using my-0 (margin-y: 0)
  inputElement?.classList.add('form-group', 'errored', 'my-0')
}

function removeErrorStylingFromInput(inputElement: HTMLElement) {
  inputElement?.classList.remove('form-group', 'errored', 'my-0')
}

// Removes code highlights if any.
function clearCodeHighlights() {
  if (!window.codeEditor) return

  const from = window.codeEditor.posFromIndex(0)
  const to = window.codeEditor.posFromIndex(window.codeEditor.getValue().length)

  for (const mark of window.codeEditor.findMarks(from, to)) {
    mark.clear()
  }
}

function allowDryRunCancellation(dryRunStatusElement: HTMLElement) {
  return (
    dryRunStatusElement.textContent?.toLowerCase() === 'queued' ||
    dryRunStatusElement.textContent?.toLowerCase() === 'inprogress'
  )
}

const errorTypeToInputId: {[key: string]: string} = {
  NONE: '',
  CONFIG_LOAD: 'secret_format',
  COMPILE_DB: 'secret_format',
  START_DELIMITER: 'before_secret',
  END_DELIMITER: 'after_secret',
  DISPLAY_NAME: 'display_name'
}
interface ErrorWithMessage {
  message: string
  error_type: string
  error_index?: number
}

// Queries the token scanning service to determine if the current test string matches the pattern
async function updatePatternMatches(
  form: HTMLFormElement,
  responseCallback: (error: ErrorWithMessage) => boolean,
  updateLabelCallback: (matchesJson: string[]) => void
) {
  // Query service for matches.
  let data
  try {
    const response = await fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: {Accept: 'application/json'}
    })
    if (response.ok) {
      data = await response.json()
    }
  } catch (e) {
    // ignore network errors
  }

  if (data) {
    if (responseCallback(data.error)) {
      if (data.has_matches) {
        const matchesJson = JSON.parse(data.matches)
        clearCodeHighlights()
        updateLabelCallback(matchesJson)
        // Highlight matches
        const testCodeTextArea = document.querySelector<HTMLTextAreaElement>('.js-test-code')!
        for (const match of matchesJson) {
          MarkMatch(window.codeEditor, testCodeTextArea, match.start, match.end)
        }
      } else {
        updateLabelCallback([])
        clearCodeHighlights()
      }
    }
  }
}

// MarkMatch converts byte positions to Javascript character positions.
//
// For context, Hypercredscan returns byte positions for its matches. However,
// in Javascript, strings are UTF16. Additionally, Codemirror uses simple Javascript
// indexing, that isn't Unicode aware. This leads to incorrect highlighting. The
// 'start' and 'end' input values are the byte positions from Hypercredscan.
//
// For example, '💩' is represented as 4 bytes. In Javascript, it has length 2
// and is composed of a surrogate pair (2 code points). '💩'.length is 2.
// Given text string such as 'hello 💩 world', this would be represented in hex as:
// ┌────────┬─────────────────────────┬─────────────────────────┬────────┬────────┐
// │00000000│ 68 65 6c 6c 6f 20 f0 9f ┊ 92 a9 20 77 6f 72 6c 64 │hello ××┊×× world│
// └────────┴─────────────────────────┴─────────────────────────┴────────┴────────┘
// The string 'world' appears at byte positions (11, 16), but in Javascript `str.slice(11, 16)` returns 'rld'.
// The correct position in Javascript, and codemirror, would be: (9, 14).
//
// When encoded using `TextEncoder#encodeInto` with '💩', we'd get an object with
// the following properties: {written: 4, read: 2}. This discrepancy informs us
// that we need to offset the start and end positions by 2.
export function MarkMatch(codeEditor: EditorFromTextArea, textArea: HTMLTextAreaElement, start: number, end: number) {
  start = GetByteOffset(textArea.value, start)
  end = GetByteOffset(textArea.value, end)

  if (start === -1 || end === -1) return

  const from = codeEditor.posFromIndex(start)
  const to = codeEditor.posFromIndex(end)
  codeEditor.markText(from, to, {className: 'text-bold hx_keyword-hl rounded-2 d-inline-block'})
}

export function GetByteOffset(str: string, pos: number): number {
  // The string iterator [...str] is unicode aware, and allows us to correctly
  // iterate over each grapheme in the string. Iterating over `str[i]` would
  // not work, as it separates the astral symbol into each of its surrogates.
  const contents = [...str]

  const encoder = new TextEncoder()

  // Astral code points in Javascript are a surrogate pair of 2 UTF16 code units, or 4 bytes
  const u8array = new Uint8Array(4)

  for (let i = 0; i < contents.length; i++) {
    const char = contents[i]
    const {written, read} = encoder.encodeInto(char, u8array)!
    if (!written || !read) {
      return -1
    }
    const diff = written - read
    if (diff === 0) {
      continue
    }

    if (i < pos) {
      pos -= diff
    }

    if (i >= pos) {
      break
    }
  }

  return pos
}
declare global {
  interface Window {
    codeEditor: EditorFromTextArea
    postProcessingExpressionCount: number
  }
}

// Handles repo selection in the save and dry run dialog for org dry runs

async function removeDryRunRepo(event: CustomEvent) {
  const form = event.currentTarget as HTMLFormElement
  event.preventDefault()
  updateDryRunSelectedRepos(form, parseInt(form.remove_repo_id.value), false)
}

async function updateDryRunSelectedRepos(form: HTMLFormElement, repoId: number, addRepo: boolean) {
  const selectedReposElement = <HTMLInputElement>document.getElementById('selected_repo_ids')
  if (!selectedReposElement) {
    return
  }

  const dryRunButton = document.querySelector<HTMLElement>('.js-org-repo-selector-dialog-dry-run-button')
  if (!dryRunButton) {
    return
  }

  const selectedReposArray = JSON.parse(selectedReposElement.value)
  const selectedReposSet = new Set(selectedReposArray)

  if (addRepo) {
    if (selectedReposSet.size < getMaxDryRunSelectedRepos()) {
      selectedReposSet.add(repoId)
    }
  } else {
    selectedReposSet.delete(repoId)
  }

  selectedReposElement.value = JSON.stringify(Array.from(selectedReposSet))

  if (selectedReposSet.size > 0) {
    dryRunButton.removeAttribute('disabled')
  } else {
    dryRunButton.setAttribute('disabled', 'true')
  }

  const formData = new FormData(form)
  formData.append('selected_repo_ids', selectedReposElement.value)

  const response = await fetch(form.action, {
    method: form.method,
    body: formData,
    headers: {Accept: 'text/fragment+html'}
  })

  // Show an error message (if request fails)
  if (response.status >= 400) {
    // eslint-disable-next-line i18n-text/no-en
    const message = 'An unknown error occurred.'
    const template = document.querySelector<HTMLTemplateElement>('template.js-flash-template')!
    template.after(new TemplateInstance(template, {className: 'flash-error', message}))
  } else {
    const target = <HTMLElement>document.querySelector('.js-dry-run-selected-repos')
    const partial = parseHTML(document, await response.text())
    target.replaceWith(partial)
  }
}

// Only click works for now, seems there is no event listener for a submit from the details dialog class, but there is one for a click
on('click', '.js-remove-dry-run-repo-form', removeDryRunRepo)

// Add a selected repo to the list and clear the search box
on('auto-complete-change', '.js-dry-run-repo-autocomplete', function (event) {
  const autoComplete = event.target as AutocompleteElement
  if (!autoComplete.value) {
    return
  }

  // eslint-disable-next-line i18n-text/no-en
  if (autoComplete.value.includes('No repositories found.')) {
    autoComplete.value = ''
    return
  }

  const addForm = <HTMLFormElement>autoComplete.closest('form')
  updateDryRunSelectedRepos(addForm, parseInt(addForm.repo_id.value), true)

  autoComplete.value = ''
})

// Don't let users accidentally submit the form when you hit enter
onKey('keydown', '.js-dry-run-repo-autocomplete-input', function (event: KeyboardEvent) {
  // TODO: Refactor to use data-hotkey
  /* eslint eslint-comments/no-use: off */
  /* eslint-disable no-restricted-syntax */
  if (event.key === 'Enter') {
    event.preventDefault()
  }
  /* eslint-enable no-restricted-syntax */
})

function getMaxDryRunSelectedRepos() {
  const maxDryRunSelectedReposElement = document.querySelector<HTMLElement>('.js-dry-run-selected-repos-max-count')!

  if (!maxDryRunSelectedReposElement) {
    return 10
  }

  const maxRepos = maxDryRunSelectedReposElement.textContent

  if (!maxRepos) {
    return 10
  }

  return parseInt(maxRepos)
}
